<template>
  <div class="case-container">
    <div v-show="Boolean(loading)" v-loading="true" element-loading-background="rgba($color: #fff, $alpha: .9))"
      class="case-loading" />
    <el-scrollbar :vertical="true" class="breadcrumb-wp">
      <span @click="onBreadcrumb(i)" v-for="(v, i) in breadcrumbList" :key="v.id" :class="{ pointer: v.id }"
        class="breadcrumb-item">{{ v.name }}</span>
    </el-scrollbar>
    <div class="case-main">
      <div class="case-content">
        <el-scrollbar style="height: 100%">
          <div class="content-tabel">
            <div class="table-hd">
              <i />
              <div class="btn-box">
                <!-- <t-btn
                  @click="uploadFile"
                  v-if="breadcrumb.showUploadFile"
                  fs="14px"
                  radius="8px"
                  w="104px"
                  h="36px">上传</t-btn>
                  <t-btn
                    v-if="breadcrumb.showCreateFolder"
                    @click="createFolder(breadcrumb.id)"
                    fs="14px"
                    radius="8px"
                    w="120px"
                    h="36px">新建文件夹</t-btn> -->
                <t-btn @click="uploadFile" fs="14px" radius="8px" w="104px" h="36px">上传</t-btn>
                <t-btn @click="createFolder(breadcrumb.id)" fs="14px" radius="8px" w="120px" h="36px">新建文件夹</t-btn>
              </div>
            </div>
            <t-pagination @currentChange="currentChange" @sizeChange="sizeChange" :pages="pages">
              <el-table :data="list" slot="table" class="t-tabel" style="width: 100%" border>
                <div slot="empty" class="nothing-box">
                  <i class="not-icon small" />
                </div>
                <el-table-column v-for="(v, i) in defalutLabelList" :key="i" :label="v.label" :min-width="v.width"
                  :prop="v.key" :sortable="v.sortable">
                  <template slot-scope="scope">
                    <span @click="onFile(scope.row)" v-if="v.key === 'name'" class="pointer pointer__hover not-select">
                      {{ scope.row[v.key] || NOT }}
                    </span>
                    <span v-else-if="v.key === 'isFile'">
                      {{ scope.row[v.key] ? '文件' : '文件夹' }}
                    </span>
                    <span v-else>{{ scope.row[v.key] || NOT }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="250" label="操作">
                  <template slot-scope="scope">
                    <span v-if="!scope.row.showRename &&
                      !scope.row.isFile &&
                      !scope.row.showDelete
                      ">{{ NOT }}</span>
                    <div class="table-btn-box" v-else>
                      <t-btn @click="rename(scope.row)" v-if="scope.row.showRename" type="primary" isText>
                        重命名
                      </t-btn>
                      <t-btn @click="download(scope.row)" v-if="scope.row.isFile" type="primary" fs="14px" isText>
                        下载
                      </t-btn>
                      <t-btn @click="del(scope.row)" v-if="scope.row.showDelete" type="warning" fs="14px" isText>
                        删除
                      </t-btn>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </t-pagination>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <create-folder @commit="createFolderCommit" ref="createFolder" />
    <rename-dialog @commit="reanmeCommit" ref="renameDialog" />
    <upload-file @createTemplate="createTemplateFile" @createBlank="createBlankFile" @upload="uploadFileChange"
      ref="uploadFile" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { download } from '@/assets/javascript/util'
import pagination from '@/mixins/pagination'
import createFolder from '@/views/case/components/create-folder'
import renameDialog from '@/views/case/components/rename-dialog'
import uploadFile from '@/views/case/components/upload-file'
const ininBreadcrumb = () => ({
  name: '首页',
  id: 0,
  typeId: 0,
})

export default {
  mixins: [pagination],
  components: {
    createFolder,
    renameDialog,
    uploadFile
  },
  data() {
    return {
      isShowAside: true,
      defalutLabelList: [{
        label: '名称',
        width: '280',
        key: 'name'
      }, {
        label: '类型',
        width: '120',
        key: 'fileTypeName'
      }, {
        label: '来源',
        width: '100',
        key: 'fromName'
      }, {
        label: '修改时间',
        width: '180',
        key: 'updateDate',
        sortable: true
      }],
      loading: 0,
      breadcrumbList: [ininBreadcrumb()],
      cur: 0
    }
  },
  computed: {
    breadcrumb() {
      const { breadcrumbList } = this

      return breadcrumbList[breadcrumbList.length - 1]
    }
  },
  watch: {
    curGroupId(val) {
      if (val) {
        this.cur = 0
        this.init()
      }
    }
  },
  methods: {
    ...mapMutations(['SETMSGDIALOG', 'SETLOADING']),
    init() {
      this.pageParams.page = 1
      this.getList()
    },
    // 删除
    del(row) {
      this.SETMSGDIALOG({
        title: '系统提示',
        message: `删除后将移入【回收站】，确定要该${row.isFile ? '文件' : '文件夹'}删除吗？`,
        commit: () => {
          const api = row.isFile ? 'deleteFile' : 'deleteFolder'

          this.$api.cases[api]({
            id: row.id
          }).then(() => {
            if (this.list.length <= 1 && this.pageParams.page > 1) {
              this.pageParams.page = this.pageParams.page - 1
            }
            this.getList()
            this.SETMSGDIALOG(null)
          })
        }
      })
    },
    // 重命名接口
    reanmeCommit(params) {
      const api = params.isFile ? 'renameByFile' : 'renameByFolder'

      this.$api.cases[api](params).then(() => {
        this.getList()
        this.$refs.renameDialog.visible = false
      })
    },
    // 重命名
    rename(row) {
      this.$refs.renameDialog.show(row.name, { id: row.id, isFile: row.isFile })
    },
    // 创建文件夹
    createFolder(id) {
      if(id == 0){
        id = this.cur
      }
      this.$refs.createFolder.show(id)
    },
    // 创建文件夹请求
    createFolderCommit(params) {
      this.$api.home.createPrivateFolder(params).then(() => {
        this.init()
        this.$refs.createFolder.visible = false
      })
    },
    // getDataList
    getList() {
      const apiList = [this.getPrivateDirectory(), this.getBreadcrumb()]

      this.loading = this.loading + 1

      Promise.all(apiList).then(res => {
        const [directoryRes, breadcrumbRes] = res

        this.list = directoryRes.items
        this.pages = directoryRes.page
        this.breadcrumbList = [ininBreadcrumb(), ...breadcrumbRes.items]

        if (this.loading > 0) {
          this.loading = this.loading - 1
        }
      }).catch(err => {
        if (this.loading > 0) {
          this.loading = this.loading - 1
        }
      })
    },
    // 获取数据
    getPrivateDirectory() {
      const { pageParams, cur } = this

      return new Promise((resolve, reject) => {
        this.$api.home.getPrivateDirectory({
          ...pageParams,
          parentId: cur || null
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取头部面包屑
    getBreadcrumb() {
      const { cur } = this

      return new Promise((resolve, reject) => {
        this.$api.home.getPrivateBreadcrumb({
          parentId: cur || 0
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 点击列表文件
    onFile(row) {
      if (row.isFile) {
        if (!row.path) return

        return window.open(row.path)
      }

      this.cur = row.id
      this.init()
    },
    // 点击面包屑
    onBreadcrumb(index) {
      const { breadcrumbList, cur } = this

      if (index === 0 || breadcrumbList[index].id === cur) {
        return
      }

      this.cur = breadcrumbList[index].id

      this.init()
    },
    // 下载
    download(row) {
      if (!row.url) return
      download(row.url, `${row.name}.${row.fileTypeName}`)
    },
    // 上传文件
    uploadFile() {
      this.$refs.uploadFile.visible = true
    },
    // 上传文件回调
    uploadFileChange(params) {
      const { breadcrumb } = this

      this.$refs.uploadFile.visible = false
      this.SETLOADING({
        title: '正在上传…',
        message: '文件正在上传中，请稍等'
      })

      this.$api.cases.uoloadFile({
        ...params,
        parentId: this.cur
      }).then(() => {
        this.init()
        this.SETLOADING(false)
      }).catch(() => {
        this.SETLOADING(false)
      })
    },
    // 创建空白文档
    createBlankFile(typeId) {
      const { breadcrumb } = this

      this.$refs.uploadFile.visible = false
      this.SETLOADING({
        title: '正在创建…',
        message: '空白文档正在创建中，请稍等'
      })

      this.$api.cases.createBlankFile({
        typeId,
        parentId: this.cur
      }).then(() => {
        this.init()
        this.SETLOADING(null)
      }).catch(() => {
        this.SETLOADING(null)
      })
    },
    // 创建模板文档
    createTemplateFile(typeId) {
      const { breadcrumb } = this

      this.$refs.uploadFile.visible = false
      this.SETLOADING({
        title: '正在创建…',
        message: '模板文档正在创建中，请稍等'
      })

      this.$api.cases.createTemplateFile({
        typeId,
        parentId: this.cur
      }).then(() => {
        this.init()
        this.SETLOADING(null)
      }).catch(() => {
        this.SETLOADING(null)
      })
    }
  },
  created() {
    const { id = 0 } = this.$route.query
    this.cur = Number(id) || 0
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.case-container {
  height: 100%;

  .case-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
  }

  .breadcrumb-wp {
    width: 100%;
    background: #F7F9FB;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    margin-bottom: 16px;

    ::v-deep .el-scrollbar__view {
      display: inline-block;
      white-space: nowrap;
    }

    ::v-deep .is-horizontal {
      height: 0;
    }

    .breadcrumb-item {
      position: relative;
      display: inline-flex;
      align-items: center;

      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9AAAB7;
      line-height: 17px;

      user-select: none;

      &:not(:last-child) {
        &::after {
          content: '';
          width: 12px;
          height: 12px;
          background: url('../../assets/image/global/icon_turnback.png') no-repeat;
          background-size: 100%;
          cursor: default;
          margin: 0 12px;
        }
      }

      &:last-child {
        font-weight: 500;
        color: #60707D;
      }
    }
  }

  .case-main {
    position: relative;
    width: 100%;
    height: calc(100% - 48px);
    background: #FFFFFF;
    border-radius: 16px;

    .case-content {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 1;
      padding: 24px;

      .content-tabel {
        .table-hd {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          .hd-title {
            font-size: 20px;
            font-weight: 600;
            color: $primary_title;
          }

          .btn-box {
            display: flex;
            align-items: center;

            >div:not(:last-child) {
              margin-right: 20px;
            }
          }
        }

        .table-btn-box {
          >div:not(:last-child) {
            margin-right: 20px;
          }
        }
      }

      .t-tabel {
        border-radius: 8px;
        border-color: transparent !important;

        &.el-table--border {
          border-color: transparent !important;
        }

        .el-table__header {
          background-color: #F4F7FA;
          border-radius: 8px 8px 0 0;
        }

        .el-table__body {}
      }
    }
  }
}
</style>
