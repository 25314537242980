<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="440px"
    title="重命名">
    <el-form
      :model="params"
      :rules="rules"
      ref="form"
      class="t-form"
      label-width="80px"
      label-position="left"
      size="medium"
	  @submit.native.prevent
      hide-required-asterisk>
      <el-form-item :label="options && options.isFile ? '文件名称' : '文件夹名称'" prop="name">
        <el-input
          v-model="params.name"
          class="input-width"
          @keypress.enter.native="commit"
          :placeholder="`请填写${options && options.isFile ? '文件' : '文件夹'}名称`"/>
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
		<t-btn
		    @click="visible=false"
		    w="78px"
		    h="36px"
			fs="14px"
			type="info mr16"
		    class="pointer bold">取消</t-btn>
		<t-btn
			@click="commit"
			w="78px"
			h="36px"
			fs="14px"
			class="pointer bold">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      options: null,
      params: {
        name: '',
      },
      rules: {
        name: [
          { required: true, message: '请填写文件夹名称' },
        ]
      },
    }
  },
  methods: {
    // 确认
    commit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('commit', { ...this.params, ...this.options})
        }
      })
    },
    // 显示
    show(name, options = null) {
      this.params.name = name
      this.options = options
      this.visible = true
    },
    // 关闭重置
    closed() {
      this.params.name = name
      this.options = null
      this.$refs.form.resetFields()
    }
  }
}
</script>
