<template>
  <el-dialog
    @open="open"
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="564px"
    title="上传文件">
    <div class="dialog-body flex" style="height: 188px">
      <div
        class="flex column ai_c jc_c dialog-body-left">
		<img  src="@/assets/image/case/upload.png"/>
		<!-- <i class="icon"></i> -->
        <t-btn
          @click="onUpload"
          w="88px"
          h="36px"
          radius="8px"
          fs="14px">
          上传
        </t-btn>
        <input @input="fileInput" ref="fileInput" type="file" style="display: none;" multiple />
      </div>
      <div class="flex column jc_c f-1 ml16">
        <div
          class="pb18">
          <p class="fs14 lh18 mb12">创建空白文档</p>
          <div class="flex">
            <t-btn
              @click="createBlankFile('xlsx')"
              class="mr8"
              w="64px"
              h="32px"
              fs="12px"
			  type="info"
              plain>EXCEL</t-btn>
            <t-btn
              @click="createBlankFile('docx')"
              class="mr8"
              w="64px"
              h="32px"
              fs="12px"
              type="info"
              plain>WORD</t-btn>
            <t-btn
              @click="createBlankFile('pptx')"
              w="64px"
              h="32px"
              fs="12px"
              type="info"
              plain>PPT</t-btn>
          </div>
        </div>
        <div class="pt18">
          <p class="fs14 lh18 mb12">创建模板</p>
          <div class="flex">
            <el-select
              v-model="templateParams.typeId"
              style="width: 100%"
              size="medium"
              placeholder="选择模板">
              <el-option
                v-for="v in fileTemplateList"
                :key="v.id"
                :value="v.id"
                :label="v.name"/>
            </el-select>
            <t-btn
              @click="createTemplateFile"
              class="ml10"
              w="78px"
              h="36px"
			  radius="8px"
			  type="success"
			  plain
              fs="12px">创建</t-btn>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import message from '@/mixins/message'

export default {
  mixins: [message],
  data() {
    return {
      visible: false,
      templateParams: {
        typeId: ''
      },
      fileTemplateList: []
    }
  },
  methods: {
    // 点击头像
    onUpload() {
      this.$refs.fileInput.click()
    },
    // 文件选择回调
    fileInput(event) {
      const { target: { files }} = event
      this.$emit("afterSelectFile",files.length)
      this.upload(files)
    },
    // 文件上传oss并暴露upload
    async upload(files) {
      try {
        const filesNum = files.length;
        let uploadDone = 1;

        if( filesNum > 10 ){
          this.$emit('uploadFail')
          this.error({
            message: "每次上传最多只能选择10个文件"
          })
          return false;
        }

        // let allow = [];
        // allow = files.filter(file=>file.size < 1024 * 1024 * 120 );
        let allow = true
        for( let i = 0 ; i < files.length ; i ++ ){
          let file = files[i];
          if( file.size > 1024 * 1024 * 500 ){
            allow = false;
            break;
          }
        }
        if( !allow ){
          this.$emit('uploadFail')
          this.error({
            message: "单个上传文件不能超过500M，请本地压缩后再上传"
          })
          return false;
        }

        if( !files ){
          this.error({
            message: "选择文件异常，请稍后再试"
          })
          return false;
        }

      

        for( let i = 0 ; i < files.length ; i ++ ){
           let file = files[i];
           const uploadFun = async (file)=>{
            const res = await this.$api.system.getGlobalList({ type: 'ossFileSign' })

            const { ossFileSign: {
              accessid,
              host,
              policy,
              signature,
              path,
              name
            } } = res

            const fileName = file.name.split('.')
            const date = new Date().getTime()
            fileName[0] = name

            let formData = new FormData
            formData.append('key', `${path}/${fileName.join('.')}`)
            formData.append('policy', policy)
            formData.append('OSSAccessKeyId', accessid)
            formData.append('signature', signature)
            formData.append('file', file)
            formData.append('success_action_status', 200);
           
            let fname = file.name.split('.');
            fname.pop();

            this.$api.system.upload(host, formData).then(()=>{
              const params = {
                path: `${path}/${fileName.join('.')}`,
                // name: file.name.split('.')[0],
                name : fname.join("."),
                size: file.size,
                filesNum,uploadDone
              }

              uploadDone ++;
              this.$emit('upload', params);
              if( this.$refs.fileInput ){
                this.$refs.fileInput.value = '';
              }
            }).catch(error=>{
              const params = {
                size: file.size,
                filesNum,uploadDone
              }
              uploadDone ++;
              this.$emit('uploadFail', params)
            })
           }
           uploadFun(file)
        }

        // files.forEach(async(file) => {
          
        // })
      } catch(err) {
        if( this.$refs.fileInput ){
          this.$refs.fileInput.value = '';
        }
        console.info(err)
      }
    },
    // 创建空白文件
    createBlankFile(typeId) {
      this.$emit('createBlank', typeId)
    },
    // 获取模板字典
    getFileTemplateList() {
      this.$api.system.getGlobalList({
        type: 'fileTemplate'
      }).then(res => {
        console.info(res.fileTemplate, 134)
        this.fileTemplateList = res.fileTemplate || []
      })
    },
    // 创建模板
    createTemplateFile() {
      const { templateParams } = this

      if (!templateParams.typeId) {
        this.error({
          message: '创建失败',
          tip: '请选择创建文档所需的模板'
        })
        return
      }

      this.$emit('createTemplate', templateParams.typeId)
    },
    // 关闭后
    closed() {
      this.templateParams.typeId = ''
    },
    // 开启
    open() {
      const { fileTemplateList } = this

      if (fileTemplateList && fileTemplateList.length) return

      this.getFileTemplateList()
    }
  }
}
</script>

<style lass="scss" scoped>
	.dialog-body{
		.dialog-body-left{
			padding: 0 45px 0 31px;
			border-right: 1px solid #f4f4f4;
			min-width: 164px;
			img{
				width: 48px;
				height: 48px;
				margin-bottom: 15px;
			}
		}
	}
</style>